(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:UserProfile
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('UserProfile', UserProfile);

  function UserProfile(EnvironmentConfig, $resource) {
    return $resource(EnvironmentConfig.API + '/users/:id/profile', {id: '@_id'}, {
      update: {
        method: 'PUT'
      }
    });
  }
}());
